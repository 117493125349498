import { Formik, Form } from "formik";
import axios from "axios";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useState } from "react";
import CustomButton from "../../../../app/common/form/CustomButton";
import CustomText from "../../../../app/common/form/CustomText";
import AdminSearchForm from "../../../forms/AdminSearchForm";
import AdminNav from "../../../nav/AdminNav";
import Select from "react-select";
import { useDispatch } from "react-redux";
import { signInUser, signInToken } from "../../../auth/authActions";
import LoadingComponent from "../../../../app/layout/LoadingComponent";

import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";

export function EditUser(props) {
    let history = useHistory();
    const { currentUser } = useSelector((state) => state.auth);
    const dispatch = useDispatch();

    let userUid = props.match.params.userId;

    const [cartTotal, setCartTotal] = useState([]);
    const [editUser, setEditUser] = useState([]);
    const [selectedUserType, setSelectedUserType] = useState("Customer");
    const [fromSyspro, setFromSyspro] = useState(false);
    const [seeStock, setSeeStock] = useState(false);
    const [warehouse99, setWarehouse99] = useState(false);
    const [guestLogin, setGuestLogin] = useState(false);
    const [paym8Privacy, setPayM8Privacy] = useState(false);
    const [priceList, setPriceList] = useState(false);

    const [loginLoading, setLoginLoading] = useState(false);
    const [loading, setLoading] = useState(true);

    const [customerOptions, setCustomerOptions] = useState([]);
    const [customerDefault, setCustomerDefault] = useState([]);

    const [supplierOptions, setSupplierOptions] = useState([]);
    const [supplierDefault, setSupplierDefault] = useState([]);

    const [supplierBuyDefault, setSupplierBuyDefault] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [customerInputValue, setCustomerInputValue] = useState("");
    const [stockInputValue, setStockInputValue] = useState("");
    const [productInputValue, setProductInputValue] = useState("");

    const handleCustomerInputChange = (value, { action }) => {
        if (action === "input-change") {
            setCustomerInputValue(value);
        }
    };

    const handleStockInputChange = (value, { action }) => {
        if (action === "input-change") {
            setStockInputValue(value);
        }
    };

    const handleProductInputChange = (value, { action }) => {
        if (action === "input-change") {
            setProductInputValue(value);
        }
    };

    let supplierOptionsWithSelectAll = [{ label: "Select All", value: "*" }, ...supplierOptions];

    useEffect(() => {
        setCartTotal(parseFloat(window.sessionStorage.getItem("itemsInBasket")));
        getUser();
    }, []);

    async function getUser() {
        let allUsers = await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}get-all-customers`,
            headers: {
                Authorization: `Bearer ${currentUser.token}`,
            },
        });

        let allUserData = allUsers.data;
        setCustomerOptions(allUserData);

        let allSuppliers = await axios({
            method: "get",
            url: `${process.env.REACT_APP_API_URL}supplier-list`,
            headers: {
                Authorization: `Bearer ${currentUser.token}`,
            },
        });

        let allSuppliersData = allSuppliers.data;
        setSupplierOptions(allSuppliersData);

        if (userUid > 0) {
            let activeUser = await axios({
                method: "post",
                url: `${process.env.REACT_APP_API_URL}admin-get-user-profile`,
                headers: {
                    Authorization: `Bearer ${currentUser.token}`,
                },
                data: {
                    userUid: userUid,
                    customer_code:
                        currentUser.isCustomer === 1 && currentUser.customer_code !== null
                            ? currentUser.customer_code
                            : currentUser.customer_code === null || currentUser.customer_code.split(",").length > 1
                            ? "None"
                            : currentUser.customer_code,
                },
            });

            if (activeUser.data[0].is_api === 1) {
                setSelectedUserType("API");
            } else if (activeUser.data[0].is_admin === 1 && activeUser.data[0].is_sales === 1) {
                setSelectedUserType("Sales");
            } else if (activeUser.data[0].is_admin === 1 && activeUser.data[0].is_lakato === 1) {
                setSelectedUserType("Lakato");
            } else if (activeUser.data[0].is_admin === 1) {
                setSelectedUserType("Admin");
            } else {
                setSelectedUserType("Customer");
            }

            setSeeStock(activeUser.data[0].see_stock === 1 ? true : false);
            setWarehouse99(activeUser.data[0].warehouse99 === 1 ? true : false);
            setGuestLogin(activeUser.data[0].guest_login === 1 ? true : false);
            setPayM8Privacy(activeUser.data[0].paym8_privacy === 1 ? true : false);
            setPriceList(activeUser.data[0].price_list === 1 ? true : false);
            setEditUser(activeUser.data[0]);

            let allowed =
                activeUser.data[0].customer_code != null
                    ? activeUser.data[0].customer_code.split(",").map(function (item) {
                          return item.trim();
                      })
                    : activeUser.data[0].customer_code;
            let defaultShowing = [];

            if (allowed != null) {
                allUserData.forEach(function (a, i) {
                    if (allowed.includes(a.value)) {
                        defaultShowing.push(allUserData[i]);
                    }
                });
            }
            setCustomerDefault(defaultShowing);

            let allowedSups =
                activeUser.data[0].supplier_stock != null
                    ? activeUser.data[0].supplier_stock.split(",").map(function (item) {
                          return item.trim();
                      })
                    : activeUser.data[0].supplier_stock;
            let allowedSupsBuy =
                activeUser.data[0].allowed_supplier != null
                    ? activeUser.data[0].allowed_supplier.split(",").map(function (item) {
                          return item.trim();
                      })
                    : activeUser.data[0].allowed_supplier;
            let defaultShowingSups = [];
            let defaultShowingSupsBuy = [];

            if (allowedSups != null) {
                allSuppliersData.forEach(function (a, i) {
                    if (allowedSups.includes(a.value)) {
                        defaultShowingSups.push(allSuppliersData[i]);
                    }
                });
            }
            if (allowedSupsBuy != null) {
                allSuppliersData.forEach(function (a, i) {
                    if (allowedSupsBuy.includes(a.value)) {
                        defaultShowingSupsBuy.push(allSuppliersData[i]);
                    }
                });
            }
            setSupplierDefault(defaultShowingSups);
            setSupplierBuyDefault(defaultShowingSupsBuy);
        }

        setLoading(false);
    }

    if (userUid !== 0) {
        var initialValues = {
            first_name: editUser.first_name,
            last_name: editUser.last_name,
            email: editUser.email,
            customer_code: editUser.customer_code,
            branch: editUser.branch,
            customer_name: editUser.customer_name,
            customer_short_name: editUser.customer_short_name,
            ship_addr_1: editUser.ship_addr_1,
            ship_addr_2: editUser.ship_addr_2,
            ship_addr_3: editUser.ship_addr_3,
            ship_addr_4: editUser.ship_addr_4,
            ship_addr_5: editUser.ship_addr_5,
            seeStock: editUser.seeStock === 1 ? true : false,
            warehouse99: editUser.warehouse99 === 1 ? true : false,
            guestLogin: editUser.guestLogin === 1 ? true : false,
            supplier_stock: editUser.supplier_stock,
            allowed_supplier: editUser.allowed_supplier,
            paym8_privacy: editUser.paym8_privacy === 1 ? true : false,
        };
    } else {
        var initialValues = {
            first_name: "",
            last_name: "",
            email: "",
            userType: "Customer",
            customer_code: "",
            supplier_stock: "",
            allowed_supplier: "",
            paym8_privacy: false,
        };
    }

    let userTypeOptions = [
        { value: "Customer", label: "Customer" },
        { value: "Admin", label: "Admin" },
        { value: "Sales", label: "Sales Rep" },
        { value: "Lakato", label: "Lakato Sales" },
        { value: "API", label: "API" },
    ];

    async function delete_user(userUid) {
        await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}admin-delete-user`,
            headers: {
                Authorization: `Bearer ${currentUser.token}`,
            },
            data: { userUid: userUid },
        });

        history.push("/user-management");
    }

    async function loginAsFeature(userUid) {
        setLoginLoading(true);

        let res = await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}admin-login-as`,
            headers: {
                Authorization: `Bearer ${currentUser.token}`,
            },
            data: { userUid: userUid },
        });

        let acc = res.data.accounts[0];
        let accHold = "N";

        if (acc !== undefined) {
            accHold = res.data.accounts[0].CustomerOnHold;
        }

        let payload = {
            email: res.data.user.email,
            token: res.data.jwt,
            logged_in_at: res.data.logged_in_at,
            name: res.data.user.first_name,
            last_name: res.data.user.last_name,
            id: res.data.user.id,
            profile_image: res.data.user.profile_image,
            isAdmin: res.data.user.is_admin,
            isSales: res.data.user.is_sales,
            isLakato: res.data.user.is_lakato,
            isCustomer: res.data.user.is_customer,
            customer_code: res.data.user.customer_code,
            branch: res.data.user.branch,
            CustomerClass: res.data.user.CustomerClass,
            customerOnHold: accHold,
            multi_account: res.data.user.multi_account,
            ship_addr_1: res.data.user.ship_addr_1,
            main_admin: true,
            see_stock: res.data.user.see_stock,
            warehouse99: res.data.user.warehouse99,
            guest_login: res.data.user.guest_login,
            supplier_stock: res.data.user.supplier_stock,
            allowed_supplier: res.data.user.allowed_supplier,
            paym8_privacy: res.data.user.paym8_privacy,
        };

        let amount = await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}total-items-in-cart`,
            headers: { Authorization: `Bearer ${res.data.jwt}` },
            data: {
                id: res.data.user.id,
                customer_code: res.data.user.customer_code,
            },
        });

        window.sessionStorage.setItem("itemsInBasket", amount.data);
        window.sessionStorage.setItem("itemsIdsInBasket", JSON.stringify([]));
        window.sessionStorage.setItem("totalItemsInBasket", JSON.stringify([]));
        window.sessionStorage.setItem("itemsClicked", JSON.stringify([]));
        window.sessionStorage.setItem("promotionItems", JSON.stringify([]));
        window.sessionStorage.setItem("promotionItemsInCart", JSON.stringify([]));
        window.sessionStorage.setItem("promotionAccepted", JSON.stringify([]));
        window.sessionStorage.setItem("categoryForSearch", JSON.stringify([]));
        window.sessionStorage.setItem("displayUpdated", new Date());
        window.sessionStorage.setItem("loggedIn", true);

        dispatch(signInUser(payload));

        if (res.data.accounts.length > 1) {
            history.push("/select-profile");
        } else {
            history.push("/shop");
        }

        setLoginLoading(false);
    }

    if (loading === true) {
        return <LoadingComponent />;
    }

    return (
        <div className="bg-white">
            <AdminSearchForm
                heading={userUid === "0" ? "Add User" : "Edit User"}
                color="text-gray-500"
                header_color="bg-gradient-to-b from-gray-300"
                crumb_color="text-black"
                breadcrumbs={[
                    { name: "Home", href: "/", count: 1 },
                    { name: "User Management", href: "/user-management", count: 2 },
                    { name: userUid === "0" ? "Add User" : "Edit User", href: "/edit/user" + userUid, count: 3 },
                ]}
            />

            <br />
            <br />

            {userUid !== "0" && (
                <div className="my-8 space-y-6 px-4 sm md:max-w-2xl lg:max-w-4xl xl:max-w-5xl m-auto">
                    <CustomButton
                        type="button"
                        text="Login As"
                        className="lkbutton bg-lk_grey_one hover:bg-lk_dark_grey_button_hover w-full border_radius_10"
                        onClick={() => loginAsFeature(userUid)}
                        submitBool={loginLoading}
                    />
                    <br />
                    <hr />
                </div>
            )}

            <Formik
                initialValues={initialValues}
                enableReinitialize={true}
                validate={(values) => {
                    const errors = {};

                    if (selectedUserType === "API") {
                        if (!values.first_name) {
                            errors.first_name = "Required";
                        }
                        if (!values.last_name) {
                            errors.last_name = "Required";
                        }
                        if (!values.email) {
                            errors.email = "Required";
                        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
                            errors.email = "Invalid email address";
                        }
                        return errors; // Return early for API users
                    }

                    // Original validation for other user types
                    if (values.userType !== "Customer" && values.userType !== undefined) {
                        if (!values.email) {
                            errors.email = "Required";
                        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
                            errors.email = "Invalid email address";
                        }
                        if (!values.first_name) {
                            errors.first_name = "Required";
                        }
                        if (!values.last_name) {
                            errors.last_name = "Required";
                        }
                    }

                    if (userUid === "0" && selectedUserType !== "API") {
                        if (!values.password) {
                            errors.password = "Required";
                        }
                    }

                    if (values.userType === "Customer" || values.userType === undefined) {
                        if (!values.email) {
                            errors.email = "Required";
                        }
                    }

                    return errors;
                }}
                onSubmit={async (values, { setErrors }) => {
                    try {
                        if (userUid !== "0") {
                            values.userId = userUid;
                            values.userType = selectedUserType;
                            values.see_stock = seeStock;
                            values.warehouse99 = warehouse99;
                            values.paym8_privacy = paym8Privacy;
                            values.price_list = priceList;
                            values.guest_login = guestLogin;

                            await axios({
                                method: "post",
                                url: `${process.env.REACT_APP_API_URL}admin-update-profile`,
                                headers: {
                                    Authorization: `Bearer ${currentUser.token}`,
                                },
                                data: values,
                            });

                            history.push("/user-management");
                        } else {
                            if (selectedUserType === "API") {
                                // Handle API user creation
                                const response = await axios({
                                    method: "post",
                                    url: `${process.env.REACT_APP_API_URL}admin-create-profile`,
                                    headers: {
                                        Authorization: `Bearer ${currentUser.token}`,
                                    },
                                    data: {
                                        ...values,
                                        is_api: true,
                                    },
                                });

                                if (response.data.status === "success") {
                                    toast.success("API user created successfully", {
                                        position: "bottom-right",
                                        autoClose: 2000,
                                    });
                                    history.push("/user-management");
                                }
                            } else {
                                values.userType = selectedUserType;
                                values.fromSyspro = fromSyspro;
                                values.see_stock = seeStock;
                                values.warehouse99 = warehouse99;
                                values.paym8_privacy = paym8Privacy;
                                values.price_list = priceList;

                                let info = await axios({
                                    method: "post",
                                    url: `${process.env.REACT_APP_API_URL}admin-create-profile`,
                                    headers: {
                                        Authorization: `Bearer ${currentUser.token}`,
                                    },
                                    data: values,
                                });

                                if (info.data == "Customer email not found on Syspro") {
                                    toast.error("Customer email not found on Syspro, please select a customer Code override to create customer", {
                                        position: "bottom-right",
                                        autoClose: 5000,
                                        closeOnClick: true,
                                    });
                                } else {
                                    history.push("/user-management");
                                }
                            }
                        }
                    } catch (error) {
                        setErrors({ message: error.response.data.error });
                    }
                }}
            >
                {({ isSubmitting, errors, setFieldValue }) => (
                    <Form className="my-8 space-y-6 px-4 sm md:max-w-2xl lg:max-w-4xl xl:max-w-5xl m-auto">
                        <ToastContainer />

                        <div className="grid grid-cols-2 gap-4">
                            {/* User Type Selection - Always visible */}
                            <div>
                                <label className="block text-sm font-medium text-gray-700 uppercase mb-5">User type</label>
                                <select
                                    name="userType"
                                    className="py-2 px-3 mt-3 shadow-sm text-sm border border_radius_5 w-full border-gray-300"
                                    onChange={(value) => {
                                        setFieldValue("userType", value.target.value);
                                        setSelectedUserType(value.target.value);
                                    }}
                                    disabled={currentUser && currentUser.isAdmin === 1 ? false : true}
                                >
                                    {userTypeOptions &&
                                        userTypeOptions.length > 0 &&
                                        userTypeOptions.map((option) => (
                                            <>
                                                {option.value === selectedUserType ? (
                                                    <option value={option.value} selected>
                                                        {option.label}
                                                    </option>
                                                ) : (
                                                    <option value={option.value}>{option.label}</option>
                                                )}
                                            </>
                                        ))}
                                </select>
                            </div>

                            {/* Fields for API User Type */}
                            {selectedUserType === "API" && (
                                <>
                                    <div>
                                        <CustomText label="First Name" type="text" name="first_name" disabled={currentUser && currentUser.isAdmin === 1 ? false : true} />
                                    </div>
                                    <div>
                                        <CustomText label="Last Name" type="text" name="last_name" disabled={currentUser && currentUser.isAdmin === 1 ? false : true} />
                                    </div>
                                    <div>
                                        <CustomText label="Email" type="text" name="email" disabled={currentUser && currentUser.isAdmin === 1 ? false : true} />
                                    </div>
                                    {userUid !== "0" && (
                                        <div>
                                            <label className="block text-sm font-medium text-gray-700 uppercase mb-5">API Token</label>
                                            <div className="flex items-center space-x-2">
                                                <input
                                                    type="text"
                                                    value={editUser.api_token || "No token generated yet"}
                                                    readOnly
                                                    className="py-2 px-3 shadow-sm text-sm border border_radius_5 w-full border-gray-300 bg-gray-50"
                                                />
                                                <button
                                                    type="button"
                                                    onClick={() => {
                                                        navigator.clipboard.writeText(editUser.api_token);
                                                        toast.success("API Token copied to clipboard!", {
                                                            position: "bottom-right",
                                                            autoClose: 2000,
                                                        });
                                                    }}
                                                    className="px-4 py-2 bg-gray-200 hover:bg-gray-300 rounded text-sm"
                                                >
                                                    Copy
                                                </button>
                                            </div>
                                            <small className="text-gray-500">This token is required for API authentication</small>
                                        </div>
                                    )}
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 uppercase mb-5">Customer Code Override</label>
                                        <Select
                                            closeMenuOnSelect={false}
                                            onInputChange={handleCustomerInputChange}
                                            inputValue={customerInputValue}
                                            blurInputOnSelect={false}
                                            defaultValue={customerDefault}
                                            options={customerOptions}
                                            onChange={(v) =>
                                                setFieldValue(
                                                    "customer_code",
                                                    Object.keys(v)
                                                        .map(function (a) {
                                                            return v[a].value;
                                                        })
                                                        .join(",")
                                                )
                                            }
                                            name="customer_code"
                                            isMulti
                                            classNamePrefix="select"
                                            className="mt-8"
                                            isDisabled={currentUser && currentUser.isAdmin === 1 ? false : true}
                                        />
                                    </div>
                                </>
                            )}

                            {/* Fields for Non-API User Types */}
                            {selectedUserType !== "API" && (
                                <>
                                    {selectedUserType !== "Customer" && (
                                        <>
                                            <div>
                                                <CustomText label="First Name" type="text" name="first_name" disabled={currentUser && currentUser.isAdmin === 1 ? false : true} />
                                            </div>
                                            <div>
                                                <CustomText label="Last Name" type="text" name="last_name" disabled={currentUser && currentUser.isAdmin === 1 ? false : true} />
                                            </div>
                                        </>
                                    )}
                                    <div>
                                        <CustomText label="Email" type="text" name="email" disabled={currentUser && currentUser.isAdmin === 1 ? false : true} />
                                    </div>
                                    <div>
                                        <CustomText label="Password" type="password" name="password" disabled={currentUser && currentUser.isAdmin === 1 ? false : true} />
                                        {userUid != 0 && <small>Only fill in this field to update the password.</small>}
                                    </div>

                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 uppercase mb-5">Customer Code Override</label>
                                        <Select
                                            closeMenuOnSelect={false}
                                            onInputChange={handleCustomerInputChange}
                                            inputValue={customerInputValue}
                                            blurInputOnSelect={false}
                                            defaultValue={customerDefault}
                                            options={customerOptions}
                                            onChange={(v) =>
                                                setFieldValue(
                                                    "customer_code",
                                                    Object.keys(v)
                                                        .map(function (a) {
                                                            return v[a].value;
                                                        })
                                                        .join(",")
                                                )
                                            }
                                            name="customer_code"
                                            isMulti
                                            classNamePrefix="select"
                                            className="mt-8"
                                            isDisabled={currentUser && currentUser.isAdmin === 1 ? false : true}
                                        />
                                    </div>

                                    {userUid !== "0" && selectedUserType !== "Customer" && (
                                        <div className="col-span-2">
                                            <label className="block text-sm font-medium text-gray-700 uppercase mb-5">
                                                <input
                                                    type="checkbox"
                                                    name="guestLogin"
                                                    className="mr-5"
                                                    checked={guestLogin}
                                                    onClick={(value) => {
                                                        setGuestLogin(value.target.checked);
                                                    }}
                                                />{" "}
                                                Guest Login
                                            </label>
                                        </div>
                                    )}

                                    <div className="col-span-2">
                                        <label className="block text-sm font-medium text-gray-700 uppercase mb-5">
                                            <input
                                                type="checkbox"
                                                name="paym8_privacy"
                                                className="mr-5"
                                                checked={paym8Privacy}
                                                onClick={(value) => {
                                                    setPayM8Privacy(value.target.checked);
                                                }}
                                            />{" "}
                                            PayM8 - don't store details (Untick on Cart by default)
                                        </label>
                                    </div>

                                    <div className="col-span-2">
                                        <label className="block text-sm font-medium text-gray-700 uppercase mb-5">
                                            <input
                                                type="checkbox"
                                                name="priceList"
                                                className="mr-5"
                                                checked={priceList}
                                                onClick={(value) => {
                                                    setPriceList(value.target.checked);
                                                }}
                                            />{" "}
                                            Show Price List Download
                                        </label>
                                    </div>

                                    <hr />
                                    <hr />

                                    {userUid !== "0" && selectedUserType !== "Customer" && (
                                        <div className="col-span-2">
                                            <label className="block text-sm font-medium text-gray-700 uppercase mb-5">
                                                <input
                                                    type="checkbox"
                                                    name="seeStock"
                                                    className="mr-5"
                                                    checked={seeStock}
                                                    onClick={(value) => {
                                                        setSeeStock(value.target.checked);
                                                    }}
                                                />{" "}
                                                See Stock Amounts
                                            </label>
                                        </div>
                                    )}

                                    <div className="col-span-2">
                                        <label className="block text-sm font-medium text-gray-700 uppercase mb-5">
                                            <input
                                                type="checkbox"
                                                name="warehouse99"
                                                className="mr-5"
                                                checked={warehouse99}
                                                onClick={(value) => {
                                                    setWarehouse99(value.target.checked);
                                                }}
                                            />{" "}
                                            See Warehouse 99 Stock
                                        </label>
                                    </div>

                                    <div className="col-span-2">
                                        {seeStock === true && (
                                            <>
                                                <label className="block text-sm font-medium text-gray-700 uppercase mb-5">Suppliers to show stock amounts for</label>
                                                <Select
                                                    closeMenuOnSelect={false}
                                                    inputValue={stockInputValue}
                                                    onInputChange={handleStockInputChange}
                                                    blurInputOnSelect={false}
                                                    value={supplierDefault}
                                                    options={supplierOptionsWithSelectAll}
                                                    onChange={(selectedOptions) => {
                                                        if (selectedOptions.some((option) => option.value === "*")) {
                                                            setSelectedOptions(supplierOptions);
                                                            setSupplierDefault(supplierOptions);
                                                            setFieldValue("supplier_stock", supplierOptions.map((option) => option.value).join(","));
                                                        } else {
                                                            setSelectedOptions(selectedOptions);
                                                            setSupplierDefault(selectedOptions);
                                                            setFieldValue("supplier_stock", selectedOptions.map((option) => option.value).join(","));
                                                        }
                                                    }}
                                                    name="supplier_stock"
                                                    isMulti
                                                    classNamePrefix="select"
                                                    className="mt-8"
                                                />
                                            </>
                                        )}
                                    </div>

                                    <hr />
                                    <hr />

                                    <div>
                                        <label className="block text-sm font-medium text-gray-700 uppercase mb-5">Allow products from suppliers</label>
                                        <Select
                                            closeMenuOnSelect={false}
                                            onInputChange={handleProductInputChange}
                                            inputValue={productInputValue}
                                            blurInputOnSelect={false}
                                            defaultValue={supplierBuyDefault}
                                            options={supplierOptions}
                                            onChange={(v) =>
                                                setFieldValue(
                                                    "allowed_supplier",
                                                    Object.keys(v)
                                                        .map(function (a) {
                                                            return v[a].value;
                                                        })
                                                        .join(",")
                                                )
                                            }
                                            name="allowed_supplier"
                                            isMulti
                                            classNamePrefix="select"
                                            className="mt-8"
                                            isDisabled={currentUser && currentUser.isAdmin === 1 ? false : true}
                                        />
                                        <small>Leave empty to allow all</small>
                                    </div>
                                </>
                            )}
                        </div>

                        {errors.message && <p className="text-lk_red text-lg">{errors.message}</p>}

                        {isSubmitting}

                        {currentUser && currentUser.isAdmin === 1 && (
                            <CustomButton
                                disabled={isSubmitting}
                                submitBool={isSubmitting}
                                type="submit"
                                text={userUid === "0" ? "Create" : "Update"}
                                className="lkbutton bg-lk_red hover:bg-lk_red_hover w-full border_radius_10"
                            />
                        )}
                    </Form>
                )}
            </Formik>

            <div className="my-8 space-y-6 px-4 sm md:max-w-2xl lg:max-w-4xl xl:max-w-5xl m-auto">
                {currentUser && currentUser.isAdmin === 1 && userUid !== "0" && (
                    <CustomButton
                        type="button"
                        text="Delete"
                        className="lkbutton bg-lk_grey_one hover:bg-lk_dark_grey_button_hover w-full border_radius_10"
                        onClick={() => window.confirm("Are you sure you wish to delete this user?") && delete_user(userUid)}
                    />
                )}
            </div>
        </div>
    );
}
